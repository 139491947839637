import { useCallback, useEffect, useState } from "react";
import styles from "./App.module.css";
import { Content } from "./components/Content";
import { Dialog } from "./components/Dialog";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import "./root.css";
import { Alert } from "./components/Dialog";
import { EXTERNAL_LINKS } from "./utils/constants";
import { Button } from "./components/Button";
import { useLocation, useSearchParams } from "react-router-dom";

function App() {
  const [isAlertOpen, setAlertOpen] = useState(false);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState("general");
  const { pathname } = useLocation();
  let [searchParams] = useSearchParams();
  const shouldOpenIntakeDialog =
    searchParams.get("mode") === "intake" && pathname === "/fileRequest";

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const openDialog = useCallback((mode) => {
    closeAlert();

    setDialogOpen(true);
    setDialogMode(mode);
  }, []);

  const closeDialog = () => {
    setDialogOpen(false);
  };
  useEffect(() => {
    if (shouldOpenIntakeDialog) openDialog("intake");
  }, [shouldOpenIntakeDialog, openDialog]);

  return (
    <div className={styles.App}>
      <Header />
      <div className={styles.content}>
        <Dialog isOpen={isDialogOpen} onClose={closeDialog}>
          <iframe
            title="File Upload"
            src={
              dialogMode === "general"
                ? EXTERNAL_LINKS.FILE_UPLOAD_GENERAL
                : EXTERNAL_LINKS.FILE_UPLOAD_GENERAL
            }
            className={styles.fileIFrame}
          />
        </Dialog>
        <Content openDialog={openDialog} openAlert={openAlert} />
        {/* Intake alert */}
        {/* <Alert isOpen={isAlertOpen}>
          <div className={styles.alert}>
            {intakeAlert}
            <Button onClick={openDialog} label="Continue" />
          </div>
        </Alert> */}
        {/* Resubmit alert */}
        {/* <Alert isOpen={isResubmitAlertOpen}>
          <div className={styles.alert}>
            {resubmitAlert}
            <Button onClick={openDialog} label="Continue" />
          </div>
        </Alert> */}
      </div>
      <Footer />
    </div>
  );
}

export default App;
