import React from "react";
import { string } from "prop-types";
import styles from "./Footer.module.css";
import packageJson from "../../package.json";

export const Footer = (props) => {
  return (
    <footer className={styles.footer}>
      © 2023 Lulu Medical Clinic. All rights reserved. {packageJson.version}
    </footer>
  );
};

Footer.propTypes = {
  className: string,
};
