import { string } from "prop-types";
import React from "react";
import { EXTERNAL_LINKS } from "../utils/constants";
import { Button } from "./Button";
import styles from "./Content.module.css";
import { Tag } from "./Tag";
import adultForm from "../resources/New Patient Intake Form (Adult).pdf";
import pediatricForm from "../resources/New Patient Intake Form (Pediatric).pdf";

const ContentOverlay = (props) => {
  const { children } = props;
  return <div className={styles.contentOverlay}>{children}</div>;
};

export const Content = ({ openDialog, openAlert }) => {
  const submitHandler = (e, mode) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    openDialog(mode);
  };

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.columnItem}>
          <div className={styles.overlay}>
            <ContentOverlay>
              <div className={styles.title}> Dr. Nancy Lin </div>
              <div className={styles.subtitle}>Family Physician MD, CCFP </div>
            </ContentOverlay>
            <div>
              Clinic Hours: Monday - Thursday: <Tag label="9AM" /> -{" "}
              <Tag label={"4PM"} />
            </div>
            <div>
              Phone hours: <Tag label="9:30AM" /> - <Tag label={"4PM"} />, lunch
              break <Tag label={"1PM"} /> - <Tag label={"1:45PM"} />
            </div>
            <span>Closed on Fridays and all statutory holidays</span>
            <br />
            <span>
              Address: 250-7031 Westminster Highway, Richmond, BC, V6X 1A3
            </span>
            <br />
            Phone: (604) 242-3680 <br />
            Fax: (604) 428-4225
          </div>
        </div>
      </div>

      <div className={styles.column}>
        <div className={styles.columnItem}>
          <div className={styles.overlayWithSubtitle}>
            <h3>New Patient?</h3>
            <div>
              {/* Dr. Nancy Lin is accepting new patients located in{" "}
              <Tag label="Richmond" />, <Tag label="Vancouver" />, and{" "}
              <Tag label="Delta" />.<br />
              Dr. Lin speaks <i>English</i> and <i>Mandarin</i>. <br />
              Dr. Lin will only accept new patients who are currently without a
              primary care provider.
              <br />
              To be placed on the waiting list, please fill out the new patient
              intake form:
              <a target="_blank" rel="noreferrer" href={adultForm}>
                <strong> adult</strong>
              </a>{" "}
              |{" "}
              <a target="_blank" rel="noreferrer" href={pediatricForm}>
                <strong>pediatric (under 18)</strong>
              </a>
              . You can submit the forms by uploading it using the button below
              or by bringing it to the clinic. */}
              Dr. Nancy Lin is not accepting new patients at this time. For
              those who have submitted their intake, meet and greet notices are
              still being rolled out. Please do not contact the clinic until it
              has at least been one month since your intake submission.
            </div>
            {/* <Button
              // onClick={(e) => submitHandler(e, "intake")}
              onClick={null}
              disabled={true}
              label="Submit Form"
            /> */}
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.columnItem}>
          <div className={styles.overlayWithSubtitle}>
            <h3>Existing Patient?</h3>
            <div className={styles.labelWithButton}>
              <span className={styles.buttonTitle}>Online booking</span> <br />
              <Button label="Click Here" url={EXTERNAL_LINKS.CORTICO} />
            </div>
            <div className={styles.labelWithButton}>
              <span className={styles.buttonTitle}>File upload</span>
              <br />
              <span> (including patient-family physician agreement)</span>{" "}
              <br />
              <Button
                onClick={(e) => submitHandler(e, "general")}
                // disabled={true}
                label="Submit Files"
              />
            </div>
          </div>
        </div>
        <div className={styles.columnItemBlackAccent}>
          <div className={styles.overlay}>
            Dr. Nancy Lin and Dr. Michelle Lee both work at the same clinic.
            <br />
            If you are Dr. Lee's patient, please{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={EXTERNAL_LINKS.LULU_MEDICAL_URL}
            >
              click here.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Content.propTypes = {
  className: string,
};
