import React from "react";
import { string } from "prop-types";
import styles from "./Tag.module.css";

export const Tag = (props) => {
  const { label } = props;
  return <span className={styles.tag}>{label}</span>;
};

Tag.propTypes = {
  className: string,
  label: string,
};
