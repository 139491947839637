import React from "react";
import styles from "./Dialog.module.css";

export const Dialog = ({ isOpen, onClose, children }) => {
  return (
    <dialog open={isOpen} className={styles.dialog}>
      {onClose && (
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
      )}
      {children}
    </dialog>
  );
};

export const Alert = ({ isOpen, onClose, children }) => {
  return (
    <dialog open={isOpen} className={styles.alert}>
      {onClose && (
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
      )}
      {children}
    </dialog>
  );
};
