import logo from "../logo.png";
import React from "react";
import { string } from "prop-types";
import styles from "./Header.module.css";
// import { HeaderMenu } from "./HeaderMenu";

export const Header = (props) => {
  return (
    <div className={styles.header}>
      <img src={logo} className={styles.logo} alt="logo" />
      {/* <HeaderMenu /> */}
    </div>
  );
};

Header.propTypes = {
  className: string,
};
