import React, { useRef } from "react";
import { string } from "prop-types";
import styles from "./Button.module.css";

export const Button = (props) => {
  const { label, url, ...attrs } = props;
  const ref = useRef();
  if (url) {
    return (
      <a
        ref={ref}
        href={url}
        // aria-label={ariaLabel}
        // className={classes}
        target="_blank"
        rel="noreferrer"
        {...attrs}
      >
        <button className={styles.button} {...attrs}>
          <span className={styles.label}>{label}</span>
        </button>
      </a>
    );
  }
  return (
    <button className={styles.button} {...attrs}>
      <span className={styles.label}>{label}</span>
    </button>
  );
};

Button.propTypes = {
  className: string,
};
