export const EXTERNAL_LINKS = {
  LULU_MEDICAL_URL: "https://www.lulumedical.ca/",
  HOME: "https://www.mdlin.ca/",
  CORTICO: "https://drmlee.cortico.ca/dr-nancy-lin/book/",
  // FILE_UPLOAD: "https://sites.google.com/mdlin.ca/site-emb/home",
  FILE_UPLOAD_GENERAL:
    "https://script.google.com/macros/s/AKfycbwMR4ypEI7M2PZ-GfyxnRSWdUALlyI6MXagjIJ5r5LBbC4EMw773XxggrVSptSocAmQ/exec",
  // FILE_UPLOAD_INTAKE:
  //   "https://script.google.com/macros/s/AKfycbwyHmsN6PMEnOYMOY2l1l2Xrt9uA4YpuoeO1eDzU1tcxJt-HzPE0MS7kRGLlaSfkqqm/exec",
  // FILE_UPLOAD_GC: "",
  // FILE_UPLOAD_RS:
  //   "https://script.google.com/macros/s/AKfycbyl7siETB9xc8KnxaqpHWahXFfTQmEuyVfdoIdNazXKtnX-WQJ8Ralr7UszXheUkW1V/exec",
};

// export const intakeAlert = (
//   <div>
//     Please ensure that the form is filled in its entirety and that it is{" "}
//     <strong>signed on page 4</strong>. If the form is incomplete, your intake
//     will be delayed.
//   </div>
// );

export const intakeAlert = (
  <div>
    Intake is currently paused for new patients. Any new submissions will be
    deleted securely from our database in keeping with our privacy standards. If
    you have already submitted your application and a revision was requested by
    Dr. Nancy Lin, please continue to proceed.
  </div>
);

export const resubmitAlert = (
  <div>
    Intake is currently paused for new patients. Any new submissions will be
    deleted securely from our database in keeping with our privacy standards. If
    you have already submitted your application and a revision was requested by
    Dr. Nancy Lin, please continue to proceed.
  </div>
);
